import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AfterPopupHideIds, PopupBanner } from '../../interfaces/popup.interface';
import { LocalStorageService } from '../../service/localstorage.service';

@UntilDestroy()
@Component({
  selector: 'banner-popup',
  templateUrl: './banner-popup.component.html',
  styleUrls: ['./banner-popup.component.scss'],
})
export class BannerPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bannerList: PopupBanner[]; closeCallback: () => void },
    private dialogRef: MatDialogRef<BannerPopupComponent>,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  @Input() bannerList: PopupBanner[] = [];
  @Input() closeCallback: () => void = () => {};

  domain = window.location.origin;

  isH5!: boolean;
  isLoading: boolean = false;
  isShowAnimation: boolean = false;
  isLogin: boolean = false;
  isHide: boolean = false;
  curBannerIdx: number = 0;
  curBanner: PopupBanner = {
    id: 0,
    title: '',
    content: '',
    image: '',
    url: '',
  };

  ngOnInit() {
    if (!this.data) return;

    this.bannerList = this.data.bannerList;
    this.closeCallback = this.data.closeCallback;
    this.isLogin = Boolean(this.localStorageService.loginToken);

    if (this.bannerList.length > 0) {
      this.curBanner = this.bannerList[this.curBannerIdx];
    }
  }

  goToLink() {
    if (this.curBanner.url) {
      this.router.navigateByUrl(this.curBanner.url);
      this.closeCallback();
      this.dialogRef.close();
    }
  }

  onShowChange() {
    this.isHide = !this.isHide;
  }

  onClose() {
    this.isShowAnimation = true;
    this.curBannerIdx += 1;

    if (this.isHide) {
      if (this.isLogin) {
        const hideIds: AfterPopupHideIds[] = this.localStorageService.getValueByJsonParse('isHideAF') || [];
        const curUid = this.localStorageService.getValueByJsonParse('uid');
        const curHideIds = hideIds.find(item => item.uid === curUid)?.ids || [];

        if (!curHideIds.includes(this.curBanner.id)) {
          let newHideIds = [];
          if (hideIds.findIndex(item => item.uid === curUid) > -1) {
            // existed already
            newHideIds = hideIds.map(item => {
              if (item.uid === curUid) {
                return {
                  uid: curUid,
                  ids: [...item.ids, this.curBanner.id],
                };
              }
              return item;
            });
          } else {
            // new user
            newHideIds = [...hideIds, { uid: curUid, ids: [this.curBanner.id] }];
          }

          this.localStorageService.setValueByJsonStringify('isHideAF', newHideIds);
        }
      } else {
        const hideIds = this.localStorageService.getValueByJsonParse('isHideBE') || [];

        if (!hideIds.includes(this.curBanner.id)) {
          this.localStorageService.setValueByJsonStringify('isHideBE', [...hideIds, this.curBanner.id]);
        }
      }
    }

    if (this.curBannerIdx < this.bannerList.length) {
      // delay 400ms for data change
      setTimeout(() => {
        this.curBanner = this.bannerList[this.curBannerIdx];
        this.isHide = false;
      }, 400);

      // delay 600ms for animation
      setTimeout(() => {
        this.isShowAnimation = false;
      }, 600);
    } else {
      this.closeCallback();
      this.dialogRef.close();
    }
  }
}

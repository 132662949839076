<div class="popup-common standard-popup" [appLoading]="isLoading">
  @if (!isLoading) {
  <i class="close-btn icon-close-simple onact96-not" (click)="onClose()"></i>
  <div class="title">{{ curBanner.title }}</div>

  <div class="content" [class.content-animation]="isShowAnimation">
    <div class="img-block">
      <img class="background-img" [src]="curBanner.image" lazy="loading" alt="" (click)="goToLink()" />
    </div>
    <div class="desc" [innerHTML]="curBanner.content | toHtmlElement"></div>
  </div>

  <div class="footer">
    <mat-checkbox [disableRipple]="true" [checked]="isHide" (change)="onShowChange()">
      {{ 'popup_not_show_again' | translate }}
    </mat-checkbox>
  </div>
  }
</div>
